html {
	width: 100%;
	height: 100%;
}

body {
	overflow: hidden;
  	width: 100%;
  	height: 100%;
	margin: 0;
	padding: 0;
	font-family: "Segoe UI", "Roboto", "Oxygen",
	"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
	width: 100%;
	height: 100%;
}

pre {
    -moz-tab-size:    4; /* Firefox 4+ */
    -o-tab-size:      4; /* Opera 11.5 & 12.1 only */
    tab-size:         4; /* Chrome 21+, Safari 6.1+, Opera 15+ */
}